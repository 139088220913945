import { Timestamp } from "firebase/firestore"

const StartupTemplate = {
        nombre: '',
        cif: '',
        tipoProyecto: 'eCommerce',
        url: '',
        fechaFundacion: Timestamp.now(),
        descripcion: '',
        entrepreneur: '',
        email: '',
        ventasAnuales: 0,
        mrr: 0,
        equipo: 0,
        customers: '0-100',
        businessModel: 'revShare',
        techStack: '',
        precioVenta: 0,
        negociable: true,
        estado: 'pendiente',
        descripcionCorta: '',
        tags: [],
        ownerId: '',
    }

export function calculateYears(foundingDate) { // birthday is a date
    var ageDifMs = Date.now() - foundingDate;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function currencyFormat(num) {
    if (!num) num = 0
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "€";
}


export default StartupTemplate
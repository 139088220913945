import React from "react";
import { Link } from "gatsby";


const SecondaryNavbar = () => {

    return (
      <div className="w-full bg-black h-12 ">
        <div className="max-w-6xl mx-auto px-4 justify-between h-full flex">
          <ul className="text-white flex items-center space-x-4">
            <li>
              <span>
                <Link to={`/`} className="category hover:text-dpink" activeClassName="nexaTextBold text-dpink">
                  Todos los artículos
                </Link>
              </span>
            </li>
            <li>
              <span>
                <Link to={`/para-emprendedores`} className="category hover:text-dpink" activeClassName="nexaTextBold text-dpink">
                  Para emprendedores
                </Link>
              </span>
            </li>
            <li>
              <span>
                <Link to={`/para-compradores`} className="category hover:text-dpink" activeClassName="nexaTextBold text-dpink">
                  Para compradores
                </Link>
              </span>
            </li>
            <li>
              <span>
                <Link to={`/para-vendedores`} className="category hover:text-dpink" activeClassName="nexaTextBold text-dpink">
                  Para vendedores
                </Link>
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
};

export default SecondaryNavbar;

import React from "react"
import { Link } from 'gatsby';
import { currencyFormat } from "../Entidades/Proyecto"


const starOffIcon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-star-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="3" y1="3" x2="21" y2="21"></line><path d="M10.012 6.016l1.981 -4.014l3.086 6.253l6.9 1l-4.421 4.304m.012 4.01l.588 3.426l-6.158 -3.245l-6.172 3.245l1.179 -6.873l-5 -4.867l6.327 -.917"></path></svg>


const Favoritos = ({ startups, quitarDeFavoritos }) => {

    const removeFromFavorites = startupId  => {
        quitarDeFavoritos(startupId);
    }

    return ( 
        <>
            <h2 className="pt-0">Favoritos</h2>
            <div className="mt-5">
                <table className="min-w-max w-full table-auto">
                    <thead >
                        <tr className="bg-dblue text-white uppercase text-sm leading-normal nexabold">
                            <th className="py-3 px-6 text-left w-1/3">Proyecto</th>
                            <th className="py-3 px-6 text-left w-1/3 text-right">Precio</th>
                            <th className="py-3 px-6 text-left">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm nexalight">
                        {startups.length>0 &&
                         startups.map((doc, index) => {
                            return (
                                <tr key={index} className={"border-b border-gray-200 hover:bg-gray-100 " + (index%2===1? "bg-gray-50":"")}>
                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                        <Link
                                            to={"/startups/"+doc.id} className="nexabold hover:underline"
                                        >
                                            Nombre Oculto
                                        </Link>
                                    </td>
                                    <td className="py-3 px-6 whitespace-nowrap text-right">{currencyFormat(doc.data().precioVenta)}</td>
                                    <td className="py-3 px-6 whitespace-nowrap flex justify-end">
                                        <a href="#" className='gray-button w-fit' onClick={() => {removeFromFavorites(doc.id)}}>
                                            {starOffIcon}
                                        </a>
                                    </td>
                                </tr>
                            );
                        })
                        }
                        {startups.length===0 &&
                            <tr className={"border-b border-gray-200 hover:bg-gray-100 "}>
                                <td className="py-3 px-6 text-left whitespace-nowrap" colSpan="3">&nbsp;</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}


export default Favoritos
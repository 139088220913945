import React, { useState } from "react"

const Contacto = ({ info, updateInfo }) => {
    const [infoContacto, setInfoContacto] = useState(info);
    const [error, setError] = useState('')
    const [modified, setModified] = useState(false)

    const handleChange = e  => {
        setInfoContacto({...infoContacto, [e.target.name]:e.target.value})
        if (info[e.target.name]!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")
        updateInfo(infoContacto)
        setModified(false)
    }

    return ( 
        <>
            <h2 className="pt-0">Información de Contacto</h2>
            <form name="Contacto" className="mt-2" onSubmit={handleSubmit}>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="entrepreneur">Nombre del emprendedor</label>
                    <input
                        type="text"
                        name="entrepreneur"
                        id="entrepreneur"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica tú nombre (No será público)"
                        value={infoContacto.entrepreneur}
                        onChange={handleChange}
                    />
                </div>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica tú email (No será público)"
                        value={infoContacto.email}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative flex sm:block flex-col-reverse w-1/2 pb-4 mt-6">
                    {
                        error ? 
                            <span className="text-red-600 capitalize nexaTextBold mb-4">
                                {error}
                            </span>
                            : null
                    }
                    <button
                        type="submit" 
                        className="text-center bg-dpink transition-all hover:shadow-xl hover:bg-pink-700 rounded-lg text-white px-10 py-4 cursor-pointer disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-pink-500"
                        disabled={!modified}
                    >
                    Actualizar
                    </button>
              </div>
            </form>

        </>
    )

}

export default Contacto
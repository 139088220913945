import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

const loginIcon= <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path><path d="M20 12h-13l3 -3m0 6l-3 -3"></path></svg>
const registerIcon = <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="9" cy="7" r="4"></circle><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path><path d="M16 11h6m-3 -3v6"></path></svg>

const Navbar = ({ blog, app }) => {
  let webURL = "";

  return (
    <div className="w-full bg-lblue">
      <div className="max-w-6xl mx-auto px-2 py-8">
        <div className="flex justify-between">
          <div className="flex cursor-pointer">
          { !blog &&
            <Link to="/">
              <StaticImage 
                src="../images/logo.png" 
                alt="" 
                className="w-56" />
            </Link>
          }
          { blog && 
            <a href={process.env.WEB_URL}>
              <StaticImage 
                src="../images/logo.png" 
                alt="" 
                className="w-56" />
            </a>
          }
          </div>
          <div className="flex self-center text-base nexabold text-neutral-600">
            <ul className="flex">
              <li>
              { !blog && 
                <Link to="/proyectos">
                  <span className="hover:text-dpink transition-all capitalize px-4 font-bold">
                    Tengo un proyecto
                  </span>
                </Link> 
              }
              { blog && 
                <a href={process.env.WEB_URL + "/proyectos"}>
                  <span className="hover:text-dpink transition-all capitalize px-4 font-bold">
                    Tengo un proyecto
                  </span>
                </a>
              }
              </li>
              <li>
              { !blog && 
                <Link to="/compradores">
                  <span className="hover:text-dpink transition-all capitalize px-4 font-bold">
                    Busco proyectos
                  </span>
                </Link> 
              }
              { blog && 
                <a href={process.env.WEB_URL + "/compradores"}>
                  <span className="hover:text-dpink transition-all capitalize px-4 font-bold">
                    Busco proyectos
                  </span>
                </a>
              }
              </li>
              <li>
              { !blog && 
                <a href={process.env.BLOG_URL}>
                  <span className="hover:text-dpink transition-all capitalize px-4 font-bold">
                    Recursos
                  </span>
                </a> 
              }
              { blog && 
                <Link to="/">
                  <span className="hover:text-dpink transition-all capitalize px-4 font-bold">
                    Recursos
                  </span>
                </Link>
              }
              </li>
            </ul>
          </div>
          <div className="flex content-end text-sm">
            <ul className="flex items-center">
              <li className="ml-8">
                
                <a href={process.env.APP_URL + "/login"} className="flex text-dpurple hover:text-dpink transition-all ">
                
                  <span className="flex capitalize NexaTextDemoLight">
                    Acceder
                  </span>
                  {loginIcon}
                </a> 
              </li>
              <li className="ml-2">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="gray-button py-2 mb-0 text-sm">
                  
                  <span className="flex capitalize NexaTextDemoLight">
                    Únete ahora
                  </span>
                  {registerIcon}
                </a> 
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import React from "react";


const Footer = ({ blog }) => {
  return (
    <footer className="w-full bg-white nexalight text-xs text-dpurple h-12 basis-3.5" >
      <div className="border-t py-3 px-4 text-center lg:text-left">
        <div className="flex flex-wrap group w-full"> {/* ROW */}
          <div className="w-full text-center lg:flex-auto lg:flex-grow-0 lg:flex-shrink-0 lg:order-6 lg:text-right lg:w-auto">
            <h1 className="nexalight text-xs p-1"> © Copyright 2021 StartupOnSale. All Rights Reserved Created By StartupOnSale</h1>
          </div>
          <div className="w-full text-center lg:flex-grow lg:text-left lg:flex-shrink-0 lg:basis-0"> <span>Made with <span className="text-red-700"><svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-fit inline" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M19.5 13.572l-7.5 7.428-7.5-7.428m0 0a5 5 0 1 1 7.5-6.566 5 5 0 1 1 7.5 6.572"></path></svg></span> in Barcelona.</span></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useContext, useEffect } from "react";
import { navigate } from "gatsby"
import { FirebaseContext } from "../Firebase"
import { getFirestore, doc, setDoc} from "firebase/firestore";


const RegistroCompleto = () => {
    const [data, setData] = useState({
        tipoCuenta: "comprador",
        nombreEmpresa: "",
        telefono: ""
    })
    const { usuario, perfilUsuario, setPerfilUsuario} = useContext(FirebaseContext);
    const [error, setError] = useState('')



    useEffect(() => {
      if (!usuario) {
        navigate("/registro/nuevo");
        return;
      }
      if (perfilUsuario && perfilUsuario.tipoCuenta && perfilUsuario.nombreEmpresa && perfilUsuario.telefono) {
        navigate("/perfil")
        return;
      }   
    }, [usuario, perfilUsuario]);

    const handleChange = e  => {
        setData({...data, [e.target.name]:e.target.value})
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")
        
        setDoc(doc(getFirestore(), "users", usuario.uid), {
          uid: usuario.uid,
          tipoCuenta: data.tipoCuenta,
          nombreEmpresa: data.nombreEmpresa,
          telefono: data.telefono
        }, { merge: true })
        .then(() => {
          setPerfilUsuario(data)
          navigate("/proyectos")
        })
        .catch(err => {
          setError(err.message)
        })
      return false

    }


  return (
        <div className="w-full bg-white">
          <div className="max-w-4xl mx-auto px-4 pt-10 pb-40">
            <h1 className=" text-center text-3xl sm:text-4xl font-bold text-dpurple nexaTextBold pb-5 sm:pb-9">
              Completa tu cuenta
            </h1>
            <form name="SignUp"  onSubmit={handleSubmit}>
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <p>¿Para qué usarás StartupOnSale?</p>
                <div className="w-full flex">
                  <div className="form-check form-check-inline border rounded-md p-4 w-1/2">
                    <input className="h-4 w-4 transition duration-200 mt-1 align-top bg-no-repeat bg-center float-left mr-2 cursor-pointer bg-red" 
                          type="radio" 
                          name="tipoCuenta" id="inlineRadio1" value="vendedor"
                          checked={data.tipoCuenta === "vendedor"}
                          onChange={handleChange}/>
                    <label htmlFor="inlineRadio1" className="form-check-label inline-block text-gray-800" >Tengo un proyecto que ofrecer</label>
                  </div>
                  <div className="form-check form-check-inline border rounded-md p-4 w-1/2">
                    <input className="rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                          type="radio" 
                          name="tipoCuenta" id="inlineRadio2" value="comprador"
                          checked={data.tipoCuenta === "comprador"}
                          onChange={handleChange}/>
                    <label htmlFor="inlineRadio2" className="form-check-label inline-block text-gray-800" >Estoy buscando un proyecto</label>
                  </div>
                </div>
              </div>
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="nombreEmpresa">Nombre Empresa</label>
                <input
                  type="text"
                  name="nombreEmpresa"
                  id="nombreEmpresa"
                  className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                  autoComplete="off"
                  value={data.nombreEmpresa}
                  onChange={handleChange}
                />
              </div>

              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="telefono">Teléfono</label>
                <input
                  type="tel"
                  name="telefono"
                  id="telefono"
                  pattern="(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?"
                  className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                  autoComplete="off"
                  value={data.telefono}
                  onChange={handleChange}
                  placeholder="+CCXXXYYYZZZ"
                />
              </div>

              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                {error ? <span className="text-red-600 capitalize nexaTextBold mb-4">{error}</span>:null}
                <button
                    type="submit"
                    className="text-center pink-button"
                >
                    Continuar
                </button>
              </div>
              
            </form>
          </div>
        </div>
  );
};

export default RegistroCompleto;

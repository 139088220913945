import React from "react";
import PropTypes from "prop-types";
import CookieConsent from 'react-cookie-consent';
import Footer from "./Footer";
import Navbar from "./Navbar";
import MobileMenu from "./MobileMenu";

const Layout = ({ children }) => {
  return (
    <div>
      <CookieConsent
                  location="bottom"
                  buttonText="Aceptar"
                  declineButtonText="Declinar"
                  enableDeclineButton="true"
                  cookieName="sos-gdpr-google-tagmanager">
         Este sitio web almacena cookies en su navegador.
         Estas cookies se utilizan para recopilar información sobre como el usuario interactúa con la web y nos permite recordarte.
         Utilizamos esta información para mejorar y personalizar la experiencia de navegacion cuándo es posible y para obtener métricas y analisis sobre el uso que se hace de él.<br/>
         Si declinas, tu información no será almacenada cuándo visites esta web. Una única cookie se almacenará en tu navegador para recordar tu preferencia de no ser rastreado.
        </CookieConsent>
      
      <div className="flex flex-col min-h-screen">
        <div className="hidden lg:block">
          <Navbar/>
        </div>
        <div className="block lg:hidden">
          <MobileMenu />
        </div>
        <div className="grow">
          {children}
        </div>
        <Footer/>
      </div>
      
    </div>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

import React from "react";


const Loading = () => {
    return (
        <div className=" flex justify-center items-center">
            <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-dpink"></div>
        </div>
    )
}

export default Loading
import React, { useState, useEffect } from "react"
import { Timestamp } from "firebase/firestore"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InformacionBasica = ({ info, updateInfo }) => {
    const [infoBasica, setInfoBasica] = useState(info);
    const [error, setError] = useState('')
    const [modified, setModified] = useState(false)

    useEffect(() => {
        setInfoBasica(info)
    },[info])

    const handleChange = e  => {
        setInfoBasica({...infoBasica, [e.target.name]:e.target.value})

        if (info[e.target.name]!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }
    }

    const handleDate = date  => {
        
        setInfoBasica({...infoBasica, fechaFundacion:Timestamp.fromDate(date)})
        
        const firstDate = Timestamp.fromDate(date);
        const secondDate = info.fechaFundacion
        if (firstDate===secondDate) {
            setModified(false)
        } else {
            setModified(true)
        }
        
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")
        updateInfo(infoBasica)
        setModified(false)
    }

    return ( 
        <>
            <h2 className="pt-0">Información Básica</h2>
            <form name="InformacionBasica" className="mt-2" onSubmit={handleSubmit}>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="nombre">Nombre</label>
                    <input
                        type="text"
                        name="nombre"
                        id="nombre"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica el nombre de tu proyecto (No será público)"
                        value={infoBasica.nombre}
                        onChange={handleChange}
                    />
                </div>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="cif">CIF</label>
                    <input
                        type="text"
                        name="cif"
                        id="cif"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica el CIF de tu startup, si tienes. (No será público)"
                        value={infoBasica.cif}
                        onChange={handleChange}
                    />
                </div>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="fechaFundacion">Fecha Fundación</label>
                    <div className="w-6/12">
                        <DatePicker id="fechaFundacion" selected={infoBasica.fechaFundacion.toDate()} onChange={(date) => handleDate(date)} dateFormat="dd/MM/yyyy" className="py-4 w-3/12 mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"/>
                    </div>
                </div>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="tipoProyecto">Tipo de Proyecto</label>
                    <select
                        name="tipoProyecto"
                        id="tipoProyecto"
                        onChange={handleChange}
                        value={infoBasica.tipoProyecto}
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        >
                            <option value="eCommerce">eCommerce</option>
                            <option value="Marketplace">Marketplace</option>
                            <option value="Shopify">Shopify</option>
                            <option value="B2B SaaS">B2B SaaS</option>
                            <option value="B2C SaaS">B2C SaaS</option>
                            <option value="Blog-Website">Blog-Website</option>
                            <option value="App">App</option>
                    </select>
                </div>

                
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="url">URL</label>
                    <input
                        type="text"
                        name="url"
                        id="url"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica la dirección web (No será pública)"
                        value={infoBasica.url}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="descripcion">Descripción</label>
                    <textarea
                        name="descripcion"
                        id="descripcion"
                        placeholder="Describe tu proyecto aquí, sus puntos fuertes, segmento, competidores. Intenta no incluir ningún dato que pueda identificar a tu proyecto directamente."
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        rows={7}
                        cols={5}
                        value={infoBasica.descripcion}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative flex sm:block flex-col-reverse w-1/2 pb-4 mt-6">
                    {
                        error ? 
                            <span className="text-red-600 capitalize nexaTextBold mb-4">
                                {error}
                            </span>
                            : null
                    }
                    <button
                        type="submit" 
                        className="text-center bg-dpink transition-all hover:shadow-xl hover:bg-pink-700 rounded-lg text-white px-10 py-4 cursor-pointer disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-pink-500"
                        disabled={!modified}
                    >
                    Actualizar
                    </button>
              </div>
            </form>

        </>
    )

}

export default InformacionBasica
import React, { useState } from "react"

const Finanzas = ({ info, updateInfo }) => {
    const [error, setError] = useState('')
    const [infoFinanzas, setInfoFinanzas] = useState(info);
    const [modified, setModified] = useState(false)

    const handleCustomerChange = (e) => {
        setInfoFinanzas({...infoFinanzas, customers:e.target.value})
        if (info['customers']!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }

    };
    const handleBusinessModelChange = (e) => {
        setInfoFinanzas({...infoFinanzas, businessModel:e.target.value})
        if (info['businessModel']!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }
    };

    const handleChange = e  => {
        if ( (e.target.name==="ventasAnuales") || (e.target.name==="mrr") || (e.target.name==="equipo"))
            setInfoFinanzas({...infoFinanzas, [e.target.name]:parseFloat(e.target.value)})
        else
            setInfoFinanzas({...infoFinanzas, [e.target.name]:e.target.value})

        if (info[e.target.name]!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")
        updateInfo(infoFinanzas)
        setModified(false)
    }


    return ( 
        <>
            <h2 className="pt-0">Información Financiera</h2>
            <form name="Finanzas" className="mt-2" onSubmit={handleSubmit}> 
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="ventasAnuales">Ventas Anuales</label>
                    <input
                        type="number"
                        name="ventasAnuales"
                        id="ventasAnuales"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica las ventas totales en un año natural completo (en euros)."
                        value={infoFinanzas.ventasAnuales}
                        onChange={handleChange}
                    />
                </div>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="mrr">MRR</label>
                    <input
                        type="number"
                        name="mrr"
                        id="mrr"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica los ingresos recurrentes mensuales (en euros)."
                        value={infoFinanzas.mrr}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="equipo">Tamaño del equipo</label>
                    <input
                        type="number"
                        name="equipo"
                        id="equipo"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica el tamaño del equipo actual."
                        value={infoFinanzas.equipo}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative flex flex-nowrap sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="customers" className="w-full flex-grow">Clientes actuales</label>
                    <select
                        name="customers"
                        id="customers"
                        onChange={handleCustomerChange}
                        value={infoFinanzas.customers}
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                    >
                        <option value="0-100">0 - 100</option>
                        <option value="101-1000">101 - 1.000</option>
                        <option value="1001-5000">1.001 - 5.000</option>
                        <option value="5001-10000">5.001 - 10.000</option>
                        <option value="10000+">10.000+</option>
                    </select>
                </div>

                <div className="relative flex flex-nowrap sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="businessModel" className="w-full flex-grow">Modelo de negocio</label>
                    <select
                        name="businessModel"
                        id="businessModel"
                        onChange={handleBusinessModelChange}
                        value={infoFinanzas.businessModel}
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                    >
                        <option value="subscription">Suscripción</option>
                        <option value="revShare">Revenue Share</option>
                        <option value="Ads">Ads</option>
                        <option value="uniquePayment">Pago único</option>
                        <option value="gratuita">Gratuita</option>
                        <option value="other">Otro</option>
                    </select>
                </div>

                <div className="relative flex sm:block flex-col-reverse w-1/2 pb-4 mt-6">
                    {
                        error ? 
                            <span className="text-red-600 capitalize nexaTextBold mb-4">
                                {error}
                            </span>
                            : null
                    }
                    <button
                        type="submit" 
                        className="text-center bg-dpink transition-all hover:shadow-xl hover:bg-pink-700 rounded-lg text-white px-10 py-4 cursor-pointer disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-pink-500"
                        disabled={!modified}
                    >
                    Actualizar
                    </button>
              </div>
            </form>

        </>
    )

}

export default Finanzas
import React, { useState, useContext, useEffect } from "react";
import { navigate, Link } from "gatsby"
import { FirebaseContext } from "../Firebase"
import { StyledFirebaseAuth } from 'react-firebaseui'
import "../../css/firebase.css"

const eye = <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>
const eyeOff = <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="3" y1="3" x2="21" y2="21"></line><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>


const Registro = () => {
    const [data, setData] = useState({
        nombre: "",
        email: "",
        password: "", 
        repassword: ""
    })
    const { firebase, usuario } = useContext(FirebaseContext);
    const [error, setError] = useState('')
    const [passwordDontMatch, setPasswordDontMatch] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);
    const [rePasswordShown, setRePasswordShown] = useState(false);

    useEffect(() => {
          if (usuario) {
              navigate("/registro/completo");
              return;
          }
    }, [usuario]);

    useEffect(() => {
      if (data.password!==data.repassword) {
        setPasswordDontMatch(true)
      } else {
        setPasswordDontMatch(false)
      }
    }, [data])

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
    const toggleRePasswordVisiblity = () => {
      setRePasswordShown(rePasswordShown ? false : true);
    };

    const handleChange = e  => {
        setData({...data, [e.target.name]:e.target.value})
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")

        if ( data.password!== data.repassword) {
          setError("Los passwords no coinciden")
          return false
        }

        firebase.auth.createUserWithEmailAndPassword(data.email, data.password)
          .then((userCredential) => { 
            userCredential.user.updateProfile({ displayName: data.nombre})
          })
          .catch(error => {
              if (error.code === "auth/email-already-in-use") {
                setError("Esta dirección de correo ya está en uso");
              }
              if ((error.code === "auth/invalid-email") || 
                  (error.code === "auth/invalid-value-(email)") ||
                  (error.code === "auth/invalid-value-(email),-starting-an-object-on-a-scalar-field")) {
                setError("La dirección de correo no es valida");
              }
              if (error.code === "auth/weak-password") {
                setError("Por favor, utiliza un password más seguro.");
              }
           })
      return false

    }


  return (
        <div className="w-full bg-white">
          <div className="max-w-sm mx-auto px-4 pt-10 pb-40">
            <h1 className=" text-center text-3xl sm:text-4xl font-bold text-dpurple nexaTextBold pb-5 sm:pb-9">
              Crea tu cuenta
            </h1>
            <form name="SignUp"  onSubmit={handleSubmit}>
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="nombre">Nombre Completo</label>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                
              </div>

              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="password">Password</label>
                <div className={"pass-wrapper flex relative w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border rounded-lg " + 
                                  ( passwordDontMatch ? 
                                    "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500 focus:outline-none" :
                                    "border-dgrey focus:outline-none focus:bg-white focus:text-gray-900" ) }>
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password"
                    autoComplete="off"
                    className="py-4 px-4 w-full focus:outline-none rounded-lg"
                    onChange={handleChange}
                    required />
                  <i className="absolute top-3 right-4" role="button" tabIndex={0} onKeyPress={togglePasswordVisiblity} onClick={togglePasswordVisiblity}>{passwordShown ? eyeOff: eye}</i>
                </div>
              </div>

              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="repassword">Confirma el Password</label>
                <div className={"pass-wrapper flex relative w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border rounded-lg " + 
                                  ( passwordDontMatch ? 
                                    "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500 focus:outline-none" :
                                    "border-dgrey focus:outline-none focus:bg-white focus:text-gray-900" ) }>
                  <input
                    type={rePasswordShown ? "text" : "password"}
                    name="repassword"
                    id="repassword"
                    className="py-4 px-4 w-full focus:outline-none rounded-lg"
                    autoComplete="off"
                    onChange={handleChange}
                    required
                  />
                  <i className="absolute top-3 right-4" role="button" tabIndex={0} onKeyPress={toggleRePasswordVisiblity} onClick={toggleRePasswordVisiblity}>{rePasswordShown ? eyeOff: eye}</i>
                </div>
              </div>
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                {error ? <span className="text-red-600 capitalize nexaTextBold mb-4">{error}</span>:null}
                <button
                    type="submit"
                    className="text-center pink-button"
                >
                    Continuar
                </button>
              </div>

              <div className="relative flex sm:block flex-col-reverse w-full pb-4 text-center">
                <span className="text-xs mt-1">¿Ya tienes cuenta? <Link className="text-dblue underline" to="/login">Entra</Link></span>
              </div>


              <div className="h-4 border-b border-gray-400 text-center p-1"> 
                  <span className="bg-white p-2">O</span>
              </div>
              
              <div className="w-full" id="google-sign-in">
                <StyledFirebaseAuth 
                  uiConfig={firebase.uiConfig}
                  firebaseAuth={firebase.auth} 
                  className="max-w-full w-full"/>
              </div>
              
            </form>
          </div>
        </div>
  );
};

export default Registro;

import React from "react";
import logo from "../images/footerlogo.png";
import {FaPhoneAlt} from "@react-icons/all-files/fa/FaPhoneAlt";
import {FaMapMarkerAlt} from "@react-icons/all-files/fa/FaMapMarkerAlt";
import {GrMail} from "@react-icons/all-files/gr/GrMail";
import {FaFacebookF} from "@react-icons/all-files/fa/FaFacebookF";
import {FaTwitter} from "@react-icons/all-files/fa/FaTwitter";
import {FaLinkedinIn} from "@react-icons/all-files/fa/FaLinkedinIn";
import {FaTelegram } from "@react-icons/all-files/fa/FaTelegram";
import {Link} from "gatsby";
import { StaticImage } from "gatsby-plugin-image";


const Mailto = ({ email, subject = "", body = "", children }) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

const Footer = ({ blog }) => {
  return (
    <footer className="w-full bg-dpurple h-128 basis-35">
      <div className="max-w-6xl mx-auto px-4 py-20">
        <div className="flex flex-col md:flex-row justify-center md:justify-between">
          <div className="mb-16  0 md:mb-0">
            <div>
              { !blog &&
                <Link to="/">
                  <StaticImage
                    src="../images/footerlogo.png"
                    alt="STARTUPONSALE"
                    className="w-60 mb-10 cursor-pointer"
                  />
                </Link>
              } 
              { blog &&
                <a href={process.env.WEB_URL}>
                  <StaticImage 
                    src="../images/footerlogo.png" 
                    alt="STARTUPONSALE" 
                    className="w-60 mb-10 cursor-pointer" 
                  />
                </a>
              }
              <div className="flex mb-2">
                <span>
                  <FaTwitter className="w-6 h-6 p-1 text-dpurple bg-white transition-all rounded-full" />
                </span>
                <span className="text-lg text-white hover:text-dpink nexalight font-normal pl-3">
                  <a href="https://twitter.com/startuponsale">@startuponsale</a>
                </span>
              </div>
              <div className="flex mb-2">
                <span>
                  <FaTelegram className="w-6 h-6 p-1 text-dpurple bg-white transition-all rounded-full" />
                </span>
                <span className="text-lg text-white hover:text-dpink nexalight font-normal pl-3">
                  <a href="https://t.me/startuponsale">@startuponsale</a>
                </span>
              </div>
              <div className="flex mb-5">
                <span>
                  <GrMail className="w-6 h-6 p-1 text-dpurple bg-white rounded-full" />
                </span>
                <span className="text-lg text-white hover:text-dpink nexalight font-normal pl-3">
                  <Mailto
                    email="hola@startuponsale.com"
                    subject="Hola quiero saber algo más"
                    body="Hola!"
                  >
                    Envianos un email
                  </Mailto>
                </span>
              </div>
              
            </div>
          </div>
          
          <div className="mb-10 md:mb-0">
            <h1 className=" text-2xl md:text-3xl font-bold text-dpink nexaTextBold pb-5">
              Plataforma
            </h1>
            <ul>
              <li className="pb-2.5">
              { !blog && 
                <Link to="/proyectos">
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Quiero vender mi proyecto
                  </span>
                </Link>
              }
              { blog && 
                <a href={process.env.WEB_URL +"/proyectos"}>
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Quiero vender mi proyecto
                  </span>
                </a>
              }
              </li>
              <li className="pb-2.5">
              { !blog && 
                <Link to="/compradores">
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Busco proyectos
                  </span>
                </Link>
              }
              { blog && 
                <a href={process.env.WEB_URL +"/compradores"}>
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Busco proyectos
                  </span>
                </a>
              }
              </li>
              <li className="pb-2.5">
                <a href={process.env.APP_URL +"/login"}>
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Marketplace
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-10 md:mb-0">
            <h1 className=" text-2xl md:text-3xl font-bold text-dpink nexaTextBold pb-5">
              Sobre Nosotros
            </h1>
            <ul>
            <li className="pb-2.5">
            { !blog && 
                <a href={process.env.BLOG_URL}>
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Recursos para emprendendores
                  </span>
                </a> 
              }
              { blog && 
                <Link to="/">
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Recursos para emprendendores
                  </span>
                </Link>
              }
              </li>
            <li className="pb-2.5">
              { !blog && 
                <Link to="/contactanos">
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Contáctanos
                  </span>
                </Link> 
              }
              { blog && 
                <a href={process.env.WEB_URL +"/contactanos"}>
                  <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                    Contáctanos
                  </span>
                </a>
              }
              </li>
              <li className="pb-2.5">
              { !blog && 
                  <Link to="/informacion/privacidad">
                    <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                      Privacidad
                    </span>
                  </Link>
              }
              { blog && 
                  <a href={process.env.WEB_URL +"/informacion/privacidad"}>
                    <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                      Privacidad
                    </span>
                  </a>
                }
              </li>
              <li className="pb-2.5">
                { !blog && 
                  <Link to="/informacion/terminos-y-condiciones">
                    <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                      Términos y condiciones
                    </span>
                  </Link> 
                }
                { blog && 
                  <a href={process.env.WEB_URL +"/informacion/terminos-y-condiciones"}>
                    <span className="text-base text-white hover:text-dpink transition-all cursor-pointer font-normal NexaTextDemoLight">
                      Términos y condiciones
                    </span>
                  </a>
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="border-t-2 border-dborder">
        <h1 className="text-xs text-white nexalight text-center py-2">
          Copyright 2021 StartupOnSale. All Rights Reserved Created By
          StartupOnSale
        </h1>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState,  useEffect, useContext } from "react"
import Loading from "../Layout/Loading"
import { StaticImage } from "gatsby-plugin-image"
import { getFirestore, getDoc, doc, collection, setDoc, addDoc, where, orderBy, getDocs, query, Timestamp } from "firebase/firestore"
import { FirebaseContext } from "../Firebase"
import { Link } from 'gatsby';
import  StartupTemplate, { currencyFormat} from "../Entidades/Proyecto";
import ConversacionModel from "../Entidades/Conversaciones"
import Mensaje from "../Mensajes/Mensaje"
import Conversacion from "../Mensajes/Conversacion"

const StartupMessages = (props) => {
    const [startup, setStartup] = useState(StartupTemplate)
    const [conversacion, setConversacion] = useState(ConversacionModel)
    const [tipo, setTipo] = useState(StartupTemplate.tipoProyecto.toLowerCase())
    const { usuario, perfilUsuario } = useContext(FirebaseContext)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {

        // Recuperamos la startup
        getDoc(doc(getFirestore(), "startups", props.id))
            .then ((startupInfo) => {
                setStartup(startupInfo.data())
                setTipo(startupInfo.data().tipoProyecto.toLowerCase())

                var conversationQuery = query(collection(getFirestore(), "conversations"),where("miembros",'array-contains', props.user),where("proyecto", "==", props.id))
                getDocs(conversationQuery)
                    .then ((result) => {

                        if (result.size===0) {
                            setConversacion({creadoPor: props.user, miembros: [props.user, startupInfo.data().ownerId], proyecto: props.id, creadoEn: Timestamp.now()})
                        } else {
                            setConversacion(result.docs[0])
                        }
                        setLoaded(true)
                    })
            })
            
    },[props.id, props.user, usuario.uid])


    if (!loaded)
        return <Loading/>
    else 
        return (
            <div className="w-full mx-auto px-2 py-1">
                <div className="bg-white mt-5 p-4">
                    
                    <div className="flex flex-wrap -m-3.5"> {/* ROW */}
                        <div className="max-w-xs w-80 p-4 border-r"> {/* COL */}
                                <h2 className="pt-0">Proyecto</h2>

                            {/* Price Block for small screens */}
                            <div className="block">
                                <div className="mx-auto mt-10">
                                <div className="mx-auto flex justify-center">
                                {tipo === "ecommerce" ? (
                                    <StaticImage src="../../images/types/ecommerce.png" alt="" layout="fixed" height={170}/>
                                    ) : (
                                    tipo === "marketplace" ? 
                                    <StaticImage src="../../images/types/marketplace.png" alt="" layout="fixed" height={170}/> : (
                                        (
                                        tipo === "app" ? 
                                            <StaticImage src="../../images/types/app.png" alt="" layout="fixed" height={170}/> : (
                                            <StaticImage src="../../images/types/shopify.png" alt="" layout="fixed" height={170}/>
                                    ))))}
                                </div>
                                <div className="px-6 pt-4 md:p-6">
                                    <p className="text-gray-500 mt-2 mb-4">{startup.descripcionCorta}</p>
                                    <div className="text-gray-500">Precio Inicial</div>
                                        <div className="nexabold mt-2 mb-0 text-xl text-dpurple">
                                            <span>{startup.precioVenta===0 ? 
                                                "Negociable" : 
                                                currencyFormat(startup.precioVenta)}</span>
                                        </div>
                                    </div>

                                    <div className="mx-6 mt-4">
                                        <Link
                                            to={"/startups/"+props.id} 
                                            className="gray-empty-button"
                                        >
                                            Volver al listado
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className="w-full max-w-full md:flex-grow md:w-0 p-4 px-6" > {/* COL */}
                            {loaded &&  <Conversacion perfil={perfilUsuario} usuario={usuario} conversacionDoc={conversacion} /> }
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default StartupMessages
import { Timestamp } from "firebase/firestore"

const ConversacionModel = {
    creadoPor: '',
    creadoEn: Timestamp.now(),
    proyecto: '',
    miembros: [],
    ultimoMensajeEn: 0

}

export const MensajeModel = {
    from: '',
    contenido: '',
    fechaEnvio: Timestamp.now(),
}


export default ConversacionModel
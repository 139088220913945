import React, { useEffect, useState } from "react";

const Mensaje = ({ mensaje, usuario, perfil }) => {
    const fechaEnviado = mensaje.fechaEnvio.toDate().toISOString().replace(/T/, ' ').replace(/\..+/, '')
    const [remitente, setRemitente] = useState('')

    useEffect(() => {
        if (perfil.tipoCuenta==='vendedor') setRemitente('Comprador')
        else setRemitente('Vendedor')
    },[mensaje, usuario] )

    return (
        <section className="bg-white w-full mt-3 border-b-2 border-dotted mb-3">
            <div className="flex flex-wrap m-0">
                <div className="flex w-full max-w-full md:flex-grow p-4 px-6 bg-slate-200"> {/* CABECERA */}
                    <div className="flex"><div className="nexabold">De: </div><div className="ml-3">{mensaje.from===usuario.uid?'Tú':remitente}</div></div>
                    <div className="flex ml-6"><div className="nexabold">Enviado: </div><div className="ml-3">{fechaEnviado}</div></div>
                </div>
                <div className="flex w-full max-w-full md:flex-grow p-4 px-6"> {/* CUERPO */}
                    {mensaje.contenido}
                </div> 
            </div>
        </section>
    )
}

export default Mensaje;

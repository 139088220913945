import React, { useState } from "react";
import { Link } from "gatsby";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { slide as Menu } from "react-burger-menu";
import { StaticImage } from "gatsby-plugin-image"

const loginIcon= <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path><path d="M20 12h-13l3 -3m0 6l-3 -3"></path></svg>
const registerIcon = <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="9" cy="7" r="4"></circle><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path><path d="M16 11h6m-3 -3v6"></path></svg>


const MobileMenu = ({ blog, app}) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  var styles = {
    bmBurgerButton: {
      position: "relative",
      width: "1.1rem",
      height: "1.1rem",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "rgba(203, 218, 255, var(--tw-bg-opacity))",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="w-full bg-lblue pt-8 pb-4 px-5 ">
      <div className="flex justify-between">
        <div className=" cursor-pointer">
        { !blog &&
            <Link to="/">
              <StaticImage 
                src="../images/logo.png" 
                alt="" 
                className="w-40" />
            </Link>
          }
          { blog && 
            <a href={process.env.WEB_URL}>
              <StaticImage 
                src="../images/logo.png" 
                alt="" 
                className="w-40" />
            </a>
          }
        </div>
        <Menu
          isOpen={isOpen}
          onClose={toggleDrawer}
          width="230px"
          right
          noOverlay
          disableOverlayClick
          styles={styles}
          customBurgerIcon={<GiHamburgerMenu />}
          customCrossIcon={false}
        >
          <div className="px-4 bg-lblue h-full">
            <ul className="flex flex-col py-10 text-sm">
              <li className="pb-4">
                { !blog && 
                  <Link to="/proyectos">
                    <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                      Tengo un proyecto
                    </span>
                  </Link> 
                }
                { blog && 
                  <a href={process.env.WEB_URL + "/proyectos"}>
                    <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                      Tengo un proyecto
                    </span>
                  </a>
                }
              </li>
              <li className="pb-4">
                { !blog && 
                  <Link to="/compradores">
                    <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                      Busco proyectos
                    </span>
                  </Link> 
                }
                { blog && 
                  <a href={process.env.WEB_URL + "/compradores"}>
                    <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                      Busco proyectos
                    </span>
                  </a>
                }
              </li>
              <li className="pb-4">
              { !blog &&
                <Link to="/newsletter">
                  <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                    Quiero saber más
                  </span>
                </Link>  
              }
              { blog && 
                <a href={process.env.WEB_URL + "/newsletter"}>
                  <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                    Quiero saber más
                  </span>
                </a>
              }
              </li>
              <li className="pb-4">
              { !blog && 
                <a href={process.env.BLOG_URL}>
                  <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                    Recursos
                  </span>
                </a> 
              }
              { blog && 
                <Link to="/">
                  <span className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                    Recursos
                  </span>
                </Link>
              }
              </li>
              <li className="mt-8">
                
                <a href={process.env.APP_URL + "/login"} className="flex text-dpurple px-4 hover:text-dpink transition-all ">
                
                  <span className="flex capitalize NexaTextDemoLight">
                    Acceder
                  </span>
                  {loginIcon}
                </a> 
              </li>
              <li className="mt-2">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="flex text-dpurple px-4 hover:text-dpink transition-all ">
                  
                  <span className="flex capitalize NexaTextDemoLight">
                    Únete ahora
                  </span>
                  {registerIcon}
                </a> 
              </li>
            </ul>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default MobileMenu;

import React, { useState,  useEffect } from "react"
import { getFirestore, doc, collection, setDoc, addDoc, orderBy, getDocs, query, Timestamp } from "firebase/firestore"
import Mensaje from "./Mensaje"
import ConversacionModel from "../Entidades/Conversaciones"

const Conversacion = ({ perfil, usuario, conversacionDoc}) => {
    const [mensajes, setMensajes] = useState([])
    const [mensajesEnviados, setMensajesEnviados] = useState([])
    const [mensaje, setMensaje] = useState({contenido: '', from: usuario.uid, fechaEnvio: Timestamp.now()})

    const [conversacion, setConversacion] = useState(ConversacionModel)
    const [conversacionNueva, setConversacionNueva] = useState(!conversacionDoc.id)
    const [conversacionId, setConversacionId] = useState("")
    
    const [loaded, setLoaded] = useState(false)
    const [modified, setModified] = useState(false)

    useEffect(() => {

        if (!conversacionNueva) {
            setConversacionId(conversacionDoc.id)
            setConversacion(conversacionDoc.data())
        
        
            var messagesQuery = query(collection(conversacionDoc.ref , "messages"), orderBy("fechaEnvio", "desc"))
            getDocs(messagesQuery)
                .then ((querySnapshot) =>{
                    setMensajes(querySnapshot.docs)
                    setMensajesEnviados([])
                    setConversacionNueva(false)
                    setLoaded(true)        
                })
        } else {
            setConversacion(conversacionDoc)
            setConversacionNueva(true)
        }
            
    },[conversacionDoc, usuario])


    const handleChange = e  => {
        setMensaje({...mensaje, [e.target.name]:e.target.value})

        if (mensaje[e.target.name]!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }
    }

    const handleSubmit = async event => {
        event.preventDefault()

        if (!conversacionNueva) {
            setDoc(doc(getFirestore(), "conversations", conversacionId), conversacion)
                .then ( () => {
                    addDoc(collection(doc(getFirestore(), "conversations", conversacionId), "messages"), mensaje)
                        .then(() => {
                            setMensajesEnviados([mensaje,...mensajesEnviados])
                            setMensaje({contenido: '', from: usuario.uid, fechaEnvio: Timestamp.now()})
                            setModified(false)
                        })
                })
        } else {
            addDoc(collection(getFirestore(), "conversations"), conversacion)
                .then ( (newDoc) => {
                    addDoc(collection(newDoc, "messages"), mensaje)
                        .then(() => {
                            setMensajesEnviados([mensaje,...mensajesEnviados])
                            setMensaje({contenido: '', from: usuario.uid, fechaEnvio: Timestamp.now()})
                            setConversacionId(newDoc.id)
                            setConversacionNueva(false)
                            setModified(false)
                        })
                })
        }
    }


    return (
        <>
            <h2 className="pt-0">Conversación con el vendedor</h2>
            <form name="Messages" onSubmit={handleSubmit}>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <textarea
                        name="contenido"
                        placeholder="Escribe un mensaje para el vendedor."
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        rows={7}
                        cols={5}
                        value={mensaje.contenido}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative flex sm:block flex-col-reverse w-1/3 pb-4 mt-2">
                    <button
                        type="submit" 
                        className="pink-button"
                        disabled={!modified}
                    >
                    Enviar Mensaje
                    </button>
                </div>
            </form>
            {(mensajes.length>0 || mensajesEnviados.length >0) &&
                <div className="mt-10">
                    <h2 className="pt-0">Tu conversación hasta ahora:</h2>
                    
                    <div className="text-sm">
                        {mensajesEnviados.length>0 &&
                            mensajesEnviados.map((doc) => {
                                return (<Mensaje key={doc.fechaEnvio} mensaje={doc} usuario={usuario} perfil={perfil}/>)
                            })
                        }
                        {loaded && mensajes.length>0 &&
                            mensajes.map((doc) => {
                                return (<Mensaje key={doc.id} mensaje={doc.data()} usuario={usuario} perfil={perfil}/>)
                            })
                        }
                        
                    </div>
                </div>
            }
        </>
    )
}



export default Conversacion;

import React, { useState } from "react"

const Venta = ({ info, updateInfo }) => {
    const [error, setError] = useState('')
    const [infoVenta, setInfoVenta] = useState(info);
    const [modified, setModified] = useState(false)

    const handleChange = e  => {
        if (e.target.name==="precioVenta")
            setInfoVenta({...infoVenta, [e.target.name]:parseFloat(e.target.value)})
        else
            setInfoVenta({...infoVenta, [e.target.name]:e.target.value})
        if (info[e.target.name]!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }
    }

    const handleToggle = e => {
        setInfoVenta({...infoVenta, [e.target.name]:e.target.checked})
        if (info[e.target.name]!==e.target.checked) {
            setModified(true)
        } else {
            setModified(false)
        }
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")
        updateInfo(infoVenta)
        setModified(false)
    }

    return ( 
        <>
            <h2 className="pt-0">Información para la Venta</h2>
            <form name="Venta" className="mt-2" onSubmit={handleSubmit}>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="precioVenta">Precio de Venta</label>
                    <input
                        type="number"
                        name="precioVenta"
                        id="precioVenta"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        autoComplete="off"
                        placeholder="Indica la valoración inicial."
                        value={infoVenta.precioVenta}
                        onChange={handleChange}
                    />
                </div>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="toggleNegociable">Negociable</label>
                    <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                        <div className="flex items-center w-full mb-12 mt-2">
                            <label htmlFor="toggleNegociable" className="flex items-center cursor-pointer">
                                <div className="mr-3 text-gray-700 font-medium"> No </div>
                                <div className="relative">
                                    <input name="negociable" type="checkbox" id="toggleNegociable" className="sr-only" defaultChecked={infoVenta.negociable} onClick={handleToggle} />
                                    <div className="block bg-white w-14 h-8 rounded-full border border-dgrey "></div>
                                    <div className="dot absolute left-1 top-1 bg-gray-500 w-6 h-6 rounded-full transition"></div>
                                </div>
                                <div className="ml-3 text-gray-700 font-medium"> Si </div>
                            </label>
                        </div>
                    </div>
                </div>

                
                <div className="relative flex sm:block flex-col-reverse w-1/2 pb-4 mt-6">
                    {
                        error ? 
                            <span className="text-red-600 capitalize nexaTextBold mb-4">
                                {error}
                            </span>
                            : null
                    }
                    <button
                        type="submit" 
                        className="text-center bg-dpink transition-all hover:shadow-xl hover:bg-pink-700 rounded-lg text-white px-10 py-4 cursor-pointer disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-pink-500"
                        disabled={!modified}
                    >
                    Actualizar
                    </button>
              </div>
            </form>

        </>
    )

}

export default Venta
import React, { useContext } from "react"
import { navigate } from "gatsby"
import { FirebaseContext } from "./Firebase"


const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { usuario } = useContext(FirebaseContext);

  if (!usuario && location.pathname !== `/login`) {
    navigate("/login");
    return null
  } 


  return <Component {...rest} />
}

export default PrivateRoute

import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { FirebaseContext } from "../Firebase"
import InformacionBasica from "./InformacionBasica"
import Finanzas from "./Finanzas"
import Tecnologia from "./Tecnologia"
import Contacto from "./Contacto"
import Venta from "./Venta"
import Loading from "../Layout/Loading"
import { getFirestore, doc, addDoc, setDoc, getDocs, query, where, collection, limit } from "firebase/firestore"
import  StartupTemplate from "../Entidades/Proyecto";


const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" className="wt-px" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><line x1="12" y1="8" x2="12.01" y2="8"></line><polyline points="11 12 12 12 12 16 13 16"></polyline></svg>
const businessIcon = <svg xmlns="http://www.w3.org/2000/svg" className="wt-px" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><ellipse cx="16" cy="6" rx="5" ry="3"></ellipse><path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path><path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path><path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path><path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path><path d="M5 15v1m0 -8v1"></path></svg>
const stackIcon = <svg xmlns="http://www.w3.org/2000/svg" className="wt-px" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="12 4 4 8 12 12 20 8 12 4"></polyline><polyline points="4 12 12 16 20 12"></polyline><polyline points="4 16 12 20 20 16"></polyline></svg>
const contactIcon = <svg xmlns="http://www.w3.org/2000/svg" className="wt-px" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4"></path><line x1="12" y1="11" x2="12" y2="11.01"></line><line x1="8" y1="11" x2="8" y2="11.01"></line><line x1="16" y1="11" x2="16" y2="11.01"></line></svg>
const cashIcon = <svg xmlns="http://www.w3.org/2000/svg" className="wt-px" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="7" y="9" width="14" height="10" rx="2"></rect><circle cx="14" cy="14" r="2"></circle><path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2"></path></svg>

const NuevaStartup = () => {
    const { usuario } = useContext(FirebaseContext);
    const [startupInfo, setStartupInfo] = useState(StartupTemplate)
    const [mostrando, setMostrando] = useState('info')
    const [cargada, setCargada] = useState(false)
    const [nueva, setNueva] = useState(true)
    const [docId, setDocId] = useState("")

    const actualizarInformacion = (newValues) => {
        setStartupInfo(newValues)

        if (!nueva)
            setDoc(doc(getFirestore(), "startups", docId), newValues)
        else (
            addDoc(collection(getFirestore(), "startups"), newValues)
                .then((docRef) => {
                    setDocId(docRef.id)
                    setNueva(false)
                })
        )
        .catch(err => {
          
        })
    }

    useEffect(() => {
        if (!usuario) {
            navigate("/login");
            return;
        }
        const q = query(collection(getFirestore(), "startups"), where("ownerId", "==", usuario.uid), limit(1));
        getDocs(q)
        //getDoc(doc(getFirestore(), "startups", usuario.uid))
            .then((querySnapshot) => {
                if (querySnapshot.size>0) {
                    setStartupInfo(querySnapshot.docs[0].data())
                    setDocId(querySnapshot.docs[0].id)
                    setNueva(false)
                } else {
                    setStartupInfo({...startupInfo, ownerId: usuario.uid})
                }
                setCargada(true)
            })
            .catch(err => {
                console.log(err)
            })
    },[usuario])

    return ( 
    <div className="w-full mx-auto px-2 py-1">
        <div className="bg-white mt-5 p-4">
            <h1 className="pb-4">Mi Proyecto</h1>
            <div className="flex flex-wrap -m-3.5"> {/* ROW */}
            
                <div className="max-w-xs w-80 p-4 border-r"> {/* COL */}
                    <div className="w-full">
                        <button className={"flex w-full p-4 "  + (mostrando==='info'?"hover:bg-rose-700  hover:text-white p-4 bg-dpink text-white": "hover:bg-rose-700  hover:text-white bg-white")}
                                onClick={()=> {setMostrando('info')}}>
                            {infoIcon}
                            <span className="pl-2">Información Básica</span>
                        </button>
                    </div>
                    <div className="w-full">
                        <button className={"flex w-full p-4 "  + (mostrando==='contact'?"hover:bg-rose-700  hover:text-white p-4 bg-dpink text-white": "hover:bg-rose-700  hover:text-white bg-white")}
                                onClick={()=> {setMostrando('contact')}}>
                            {contactIcon}
                            <span className="pl-2">Contacto</span>
                        </button>
                    </div>
                    <div className="w-full">
                        <button className={"flex w-full p-4 "  + (mostrando==='finanzas'?"hover:bg-rose-700  hover:text-white p-4 bg-dpink text-white": "hover:bg-rose-700  hover:text-white bg-white")}
                                onClick={()=> {setMostrando('finanzas')}}>
                            {businessIcon}
                            <span className="pl-2">Finanzas</span>
                        </button>
                    </div>
                    <div className="w-full">
                        <button className={"flex w-full p-4 "  + (mostrando==='tech'?"hover:bg-rose-700  hover:text-white p-4 bg-dpink text-white": "hover:bg-rose-700  hover:text-white bg-white")}
                                onClick={()=> {setMostrando('tech')}} >
                        {stackIcon}
                        <span className="pl-2">Tecnología</span>
                        </button>
                    </div>
                    <div className="w-full">
                        <button className={"flex w-full p-4 "  + (mostrando==='venta'?"hover:bg-rose-700  hover:text-white p-4 bg-dpink text-white": "hover:bg-rose-700  hover:text-white bg-white")}
                                onClick={()=> {setMostrando('venta')}} >
                        {cashIcon}
                        <span className="pl-2">Venta</span>
                        </button>
                    </div>
                </div>
                <div className="w-full max-w-full md:flex-grow md:w-0 p-4 px-6" > {/* COL */}
                    <div className="max-w-4xl">
                        {!cargada && <Loading/>}
                        {cargada && mostrando==='info' && <InformacionBasica info={startupInfo} updateInfo={actualizarInformacion}/> }
                        {cargada && mostrando==='finanzas' && <Finanzas info={startupInfo} updateInfo={actualizarInformacion}/> }
                        {cargada && mostrando==='tech' && <Tecnologia info={startupInfo} updateInfo={actualizarInformacion}/> }
                        {cargada && mostrando==='contact' && <Contacto info={startupInfo} updateInfo={actualizarInformacion}/>}
                        {cargada && mostrando==='venta' && <Venta info={startupInfo} updateInfo={actualizarInformacion}/>}
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    )

}

export default NuevaStartup
import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { FirebaseContext } from  "../Firebase"
import ProfileMenu from "./ProfileMenu"
import { getDocs, query, where, collection, orderBy, getFirestore, Timestamp } from "firebase/firestore"

const directoryIcon = <svg xmlns="http://www.w3.org/2000/svg" className="mt-px" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M13 5h8"></path><path d="M13 9h5"></path><path d="M13 15h8"></path><path d="M13 19h5"></path><rect x="3" y="4" width="6" height="6" rx="1"></rect><rect x="3" y="14" width="6" height="6" rx="1"></rect></svg>
const messagesIcon = <svg xmlns="http://www.w3.org/2000/svg" className="mt-px" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"></path><path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"></path></svg>
const newStartupIcon = <svg xmlns="http://www.w3.org/2000/svg" className="mt-px" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="16" height="16" rx="2"></rect><line x1="9" y1="12" x2="15" y2="12"></line><line x1="12" y1="9" x2="12" y2="15"></line></svg>

const Navbar = () => {
  const { usuario, perfilUsuario } = useContext(FirebaseContext);
  const [mensajesNuevos, setMensajesNuevos] = useState(0)
  
  useEffect(() => {

    // Recuperamos los mensajes
    if (usuario!=null) {
      const conditions = [where("to", "==", usuario.uid), orderBy("fechaEnvio", "desc")]
      var myQuery = query(collection(getFirestore(), "messages"),...conditions)

      getDocs(myQuery)
        .then((querySnapshot) =>{
          setMensajesNuevos(querySnapshot.size)
        })
    }

  },[usuario] )


  return (
    <div className={"w-full bg-white border-b "+process.env.CSS_CLASS} >
      <div className="mx-auto px-2 py-1">
          <div className="flex justify-content-between items-center flex-wrap">
            <div className="flex items-center flex-shrink-0">
              <Link to="/login">
                <StaticImage 
                  src="../../images/logo.png" 
                  alt="" 
                  className="w-56" 
                  layout="fixed" height={50}/>
              </Link>
            </div>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              {perfilUsuario && perfilUsuario.tipoCuenta && perfilUsuario.tipoCuenta==="vendedor" &&
                <ul className="flex">
                  <li>
                      <Link to="/nueva" className="flex text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                        {newStartupIcon}
                        <span className="ml-1.5">Nuevo Proyecto</span>
                      </Link>

                  </li>
                </ul>
              }
              {perfilUsuario && usuario && 
                <ul className="flex">
                  <li>
                    <Link to="/proyectos" className="flex text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                      {directoryIcon}
                      <span className="ml-1.5">Directorio</span>
                    </Link> 
                  </li>
                  <li>
                    <Link to="/mensajes" className="flex text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                      {messagesIcon}
                      <span className="ml-1.5">Mensajes</span>
                      {
                       mensajesNuevos>0 &&
                        <span className="hidden rounded-full bg-red-600 absolute w-2.5 h-2.5 ml-4 -mt-1"></span>
                      }
                    </Link> 
                  </li>
                </ul>
              }
            </div>
            <div className="">
              { perfilUsuario && usuario ?
                  <><ProfileMenu/></>
                  :
                    null
              }
            </div>
          </div>
      </div>
    </div>
  );
};

export default Navbar;

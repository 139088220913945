import React, { useState,  useEffect } from "react"
import StartupCard from "./Card"
import Loading from "../Layout/Loading"
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"

const Proyectos = () => {
    const [startups, setStartups] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [filtro, setFiltro] = useState(null);

    useEffect(() => {
        const conditions = [where("estado", "==", 'publicada')]
       
        if (filtro != null) {
            // Query por tipo de proyecto
            let tipoquery  = []

            if (filtro.eCommerce) tipoquery.push('eCommerce')
            if (filtro.Marketplace) tipoquery.push('Marketplace')
            if (filtro.Shopify) tipoquery.push('Shopify')
            if (filtro['B2B SaaS']) tipoquery.push('B2B SaaS')
            if (filtro['B2C SaaS']) tipoquery.push('B2C SaaS')
            if (filtro['Blog-Website']) tipoquery.push('Blog-Website')
            if (filtro['App']) tipoquery.push('App')
            
            if (tipoquery.length>0)
                conditions.push(where("tipoProyecto", "in", tipoquery))
            
            if (filtro.coningresos && filtro.siningresos)
                conditions.push(where("mrr", ">=", 0))
            else if (filtro.coningresos)
                conditions.push(where("mrr", ">", 0))
            else if (filtro.siningresos)
                conditions.push(where("mrr", "==", 0))
        }

        var myQuery = query(collection(getFirestore(), "startups"),...conditions)
        getDocs(myQuery)
            .then ((querySnapshot) =>{
                setStartups(querySnapshot)
                setLoaded(true)
            })
    }, [filtro])

    const handleToggle = e => {
        setFiltro({...filtro, [e.target.name]:e.target.checked})
    }

    return (
        <div className="w-full mx-auto px-2 py-1">
            <div className="bg-white mt-5 p-4">
                <h1 className="pb-4">Encuentra el proyecto que buscas</h1>
                
                <div className="flex flex-wrap -m-3.5"> {/* ROW */}
                    <div className="max-w-xs w-80 p-4 border-r"> {/* COL */}
                        <div className="w-full">
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-fit inline" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
                                </svg>
                                <span>Filtrar</span>
                            </p>
                            <div className="mt-6 text-xs">
                                <form>
                                    
                                    <div className="relative flex sm:block flex-col w-full pb-4">
                                        <p className="w-full nexabold">Tipo de proyecto</p>
                                        <div className="flex flex-col">
                                            <div className="flex flex-col">
                                                <label className="inline-flex flex-initial items-center mt-3">
                                                    <input type="checkbox" id="eCommerce" name="eCommerce" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                    <label htmlFor="eCommerce" className="ml-2 text-gray-700">eCommerce</label>
                                                </label>
                                                <label className="inline-flex flex-initial items-center mt-3 ">
                                                    <input type="checkbox" id="Marketplace" name="Marketplace" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                    <label htmlFor="Marketplace" className="ml-2 text-gray-700">Marketplace</label>
                                                </label>
                                                <label className="inline-flex flex-initial items-center mt-3 ">
                                                    <input type="checkbox" id="Shopify" name="Shopify" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                    <label htmlFor="Shopify" className="ml-2 text-gray-700">Shopify</label>
                                                </label>
                                            </div>
                                            <div className="flex flex-col">
                                                <label className="inline-flex flex-initial items-center mt-3 ">
                                                    <input type="checkbox" id="B2B SaaS" name="B2B SaaS" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                    <label htmlFor="B2B SaaS" className="ml-2 text-gray-700">B2B SaaS</label>
                                                </label>
                                                <label className="inline-flex flex-initial items-center mt-3 ">
                                                    <input type="checkbox" id="B2C SaaS" name="B2C SaaS" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                    <label htmlFor="B2C SaaS" className="ml-2 text-gray-700">B2C SaaS</label>
                                                </label>
                                                <label className="inline-flex flex-initial items-center mt-3 ">
                                                    <input type="checkbox" id="Blog-Website" name="Blog-Website" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                    <label htmlFor="Blog-Website" className="ml-2 text-gray-700">Blog-Website</label>
                                                </label>
                                            </div>
                                            <div className="flex flex-col">
                                                <label className="inline-flex flex-initial items-center mt-3 ">
                                                    <input type="checkbox" id="App" name="App" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                    <label htmlFor="App" className="ml-2 text-gray-700">App</label>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="relative flex flex-col sm:block w-full pb-4">
                                        <p className="w-full nexabold">Con ingresos</p>
                                        <div className="flex flex-col">
                                            <label className="inline-flex flex-initial items-center mt-3 ">
                                                <input type="checkbox" id="coningresos" name="coningresos" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                <label htmlFor="coningresos" className="ml-2 text-gray-700">Si</label>
                                            </label>
                                            <label className="inline-flex flex-initial items-center mt-3 ">
                                                <input type="checkbox" id="siningresos" name="siningresos" className="form-checkbox h-4 w-4 text-pink-600" onChange={handleToggle}/>
                                                <label htmlFor="siningresos" className="ml-2 text-gray-700">No</label>
                                            </label>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            
                        </div>
                    </div>

                    <div className="w-full max-w-full md:flex-grow md:w-0 p-4 px-6" > {/* COL */}
                        <div className="">
                        {!loaded && <Loading/>}
                        {loaded &&
                            startups.docs.map((doc) => {
                                return (<StartupCard key={doc.id} uid={doc.id} startup={doc.data()}/>);
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Proyectos
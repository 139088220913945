import React, { useState } from "react"

const Tecnologia = ({ info, updateInfo }) => {
    const [error, setError] = useState('')
    const [infoTecnologia, setInfoTecnologia] = useState(info);
    const [modified, setModified] = useState(false)

    const handleChange = e  => {
        setInfoTecnologia({...infoTecnologia, [e.target.name]:e.target.value})
        if (info[e.target.name]!==e.target.value) {
            setModified(true)
        } else {
            setModified(false)
        }
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")
        updateInfo(infoTecnologia)
        setModified(false)
    }

    return ( 
        <>
            <h2 className="pt-0">Stack Tecnológico</h2>
            <form name="Tecnologia" className="mt-2" onSubmit={handleSubmit}>
                <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                    <label htmlFor="techStack">Descripción</label>
                    <textarea
                        id="techStack"
                        name="techStack"
                        placeholder="Indica las características principales de la tecnología base del proyecto"
                        className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg px-4 focus:outline-none focus:bg-white focus:text-gray-900"
                        rows={7}
                        cols={5}
                        value={infoTecnologia.techStack}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative flex sm:block flex-col-reverse w-1/2 pb-4 mt-6">
                    {
                        error ? 
                            <span className="text-red-600 capitalize nexaTextBold mb-4">
                                {error}
                            </span>
                            : null
                    }
                    <button
                        type="submit" 
                        className="text-center bg-dpink transition-all hover:shadow-xl hover:bg-pink-700 rounded-lg text-white px-10 py-4 cursor-pointer disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-pink-500"
                        disabled={!modified}
                    >
                    Actualizar
                    </button>
              </div>
            </form>

        </>
    )

}

export default Tecnologia
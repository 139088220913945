import React, { useContext, useEffect, useState } from "react"
import Loading from "../Layout/Loading"
import { getFirestore, collection, query, where, getDocs, documentId} from "firebase/firestore"
import { FirebaseContext } from  "../Firebase"
import  { currencyFormat} from "../Entidades/Proyecto";
import Conversacion from "./Conversacion";

const Mensajes = () => {
    const { usuario, perfilUsuario } = useContext(FirebaseContext);

    const [conversaciones, setConversaciones] = useState([])
    const [conversacionesHash, setConversacionesHash] = useState([])
    const [proyectos, setProyectos] = useState(null)
    const [mostrando, setMostrando] = useState('')
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {

        // Recuperamos las conversaciones
        if (usuario!=null) {
          var myQuery = query(collection(getFirestore(), "conversations"),where('miembros', 'array-contains', usuario.uid))
    
          getDocs(myQuery)
            .then((querySnapshot) =>{
                setConversaciones(querySnapshot.docs)
                var conversacionesHashed = querySnapshot.docs.reduce(function(map, obj) {
                    map[obj.data().proyecto] = obj;
                    return map;
                }, {})
                setConversacionesHash(conversacionesHashed)

                var proyectoIds = []
                querySnapshot.docs.forEach((conversacion) => {
                    proyectoIds.push(conversacion.data().proyecto)
                })
                setMostrando(proyectoIds[0])

                if (proyectoIds.length>0) {
                    var proyectosQuery = query(collection(getFirestore(), "startups"),where(documentId(), 'in', proyectoIds))
                    getDocs(proyectosQuery)
                        .then ( proyectosSnapshot => {

                            var result = proyectosSnapshot.docs.reduce(function(map, obj) {
                                            map[obj.id] = obj.data();
                                            return map;
                                        }, {})
                            setProyectos(result)
                            setLoaded(true)  
                        })
                } else {
                    setProyectos([])
                    setLoaded(true)
                }

            })
        }
        return () => {
            // This worked for me
          };
    
      },[usuario] )

    if (!loaded)
        return <Loading/>
    else 
        return (
            <div className="w-full mx-auto px-2 py-1">
                <div className="bg-white mt-5 p-4">
                    <h1 className="pb-4">Todos tus mensajes</h1>
                    
                    <div className="flex flex-wrap -m-3.5"> {/* ROW */}
                        <div className="max-w-xs w-80 p-4 border-r"> {/* COL */}
                            <div className="w-full mt-2 text-xs">
                                <p className="w-full nexabold">Proyecto</p>
                            </div>

                            {conversaciones.length>0 &&
                                conversaciones.map((doc) => {
                                    return (
                                        <div className="w-full" key={doc.data().proyecto}>
                                            <button className={"flex w-full p-4 "  + (mostrando===doc.data().proyecto?"hover:bg-rose-700  hover:text-white p-4 bg-dpink text-white": "hover:bg-rose-700  hover:text-white bg-white")}
                                                    onClick={()=> {setMostrando(doc.data().proyecto)}} >
                                                <span className="pl-2">{proyectos[doc.data().proyecto].tipoProyecto} - {proyectos[doc.data().proyecto].precioVenta===0 ? 
                                                                                                    "Negociable" : 
                                                                                                    currencyFormat(proyectos[doc.data().proyecto].precioVenta)}
                                                </span>
                                            </button>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        <div className="w-full max-w-full md:flex-grow md:w-0 p-4 px-6" > {/* COL */}
                        { loaded && conversaciones.length>0 && <Conversacion perfil={perfilUsuario} usuario={usuario} conversacionDoc={conversacionesHash[mostrando]} /> }
                        { loaded && conversaciones.length===0 && 
                            <>No tienes mensajes actualmente</>
                        }
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default Mensajes

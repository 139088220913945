import React, { useState, useContext, useEffect } from "react";
import { Link } from "gatsby";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { slide as Menu } from "react-burger-menu";
import { StaticImage } from "gatsby-plugin-image"
import { FirebaseContext } from  "../Firebase"
import ProfileMenu from "./ProfileMenu"

const loginIcon= <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path><path d="M20 12h-13l3 -3m0 6l-3 -3"></path></svg>
const registerIcon = <svg xmlns="http://www.w3.org/2000/svg" className="" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="9" cy="7" r="4"></circle><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path><path d="M16 11h6m-3 -3v6"></path></svg>
const directoryIcon = <svg xmlns="http://www.w3.org/2000/svg" className="mt-px" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M13 5h8"></path><path d="M13 9h5"></path><path d="M13 15h8"></path><path d="M13 19h5"></path><rect x="3" y="4" width="6" height="6" rx="1"></rect><rect x="3" y="14" width="6" height="6" rx="1"></rect></svg>
const messagesIcon = <svg xmlns="http://www.w3.org/2000/svg" className="mt-px" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"></path><path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"></path></svg>
const newStartupIcon = <svg xmlns="http://www.w3.org/2000/svg" className="mt-px" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="16" height="16" rx="2"></rect><line x1="9" y1="12" x2="15" y2="12"></line><line x1="12" y1="9" x2="12" y2="15"></line></svg>


const MobileMenu = () => {
  const { usuario, perfilUsuario } = useContext(FirebaseContext);
  const [isOpen, setIsOpen] = useState(false);

  
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  var styles = {
    bmBurgerButton: {
      position: "relative",
      width: "1.1rem",
      height: "1.1rem",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      margintop: "22px"
    },
    bmMenu: {
      
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="w-full bg-white border-b ">
      <div className="mx-auto px-2 py-1">
        <div className="flex justify-content-between items-center flex-wrap">
            <div className="flex items-center flex-grow ">
              <Link to="/">
                <StaticImage 
                  src="../../images/logo.png" 
                  alt="" 
                  className="w-56" 
                  layout="fixed" height={50}/>
              </Link>
            </div>
            { perfilUsuario && usuario && 
              <div className="block lg:flex lg:items-center lg:w-auto pr-4">
                <Menu
                  isOpen={isOpen}
                  onClose={toggleDrawer}
                  width="230px"
                  right
                  noOverlay
                  disableOverlayClick
                  styles={styles}
                  customBurgerIcon={<GiHamburgerMenu />}
                  customCrossIcon={false}
                >
                  <div className="px-4 bg-white border-l h-full text-sm flex-col">
                      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                        {perfilUsuario && perfilUsuario.tipoCuenta && perfilUsuario.tipoCuenta==="vendedor" &&
                          <ul className="flex flex-col py-2 text-sm">
                            <li className="pb-2">
                                <Link to="/nueva" className="flex text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                                  {newStartupIcon}
                                  <span className="ml-1.5">Nuevo Proyecto</span>
                                </Link>

                            </li>
                          </ul>
                        }
                        {perfilUsuario && usuario && 
                          <ul className="flex flex-col py-2 text-sm">
                            <li className="pb-4">
                              <Link to="/proyectos" className="flex text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                                {directoryIcon}
                                <span className="ml-1.5">Directorio</span>
                              </Link> 
                            </li>
                            <li className="pb-4">
                              <Link to="/mensajes" className="flex text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold">
                                {messagesIcon}
                                <span className="ml-1.5">Mensajes</span>
                                
                              </Link> 
                            </li>
                          </ul>
                        }
                      </div>
                      <div className="">
                        { perfilUsuario && usuario ?
                            <><ProfileMenu/></>
                            :
                              null
                        }
                      </div>
                  </div>
                </Menu>
              </div>
            }
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;

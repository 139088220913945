import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { FirebaseContext } from "../Firebase"
import { getFirestore, collection, query, where, getDocs, setDoc, doc, documentId} from "firebase/firestore"
import Loading from "../Layout/Loading"
import Favoritos from "./Favoritos"

const starIcon = <svg xmlns="http://www.w3.org/2000/svg" className="wt-px" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path></svg>

const Dashboard = () => {
    const { usuario, perfilUsuario, setPerfilUsuario} = useContext(FirebaseContext);
    const [mostrando, setMostrando] = useState('favoritos')
    const [cargada, setCargada] = useState(false)
    const [startups, setStartups] = useState([])

    useEffect(() => {
        if (!usuario || !perfilUsuario) {
            navigate("/login");
            return;
        }

        console.log("STARTUPS FAVORITAS=" + perfilUsuario.favoritos.length)
        console.log("PERFIL USUARIO=" + JSON.stringify(perfilUsuario))

        if (perfilUsuario.favoritos && perfilUsuario.favoritos.length>0) {
            const q = query(collection(getFirestore(), "startups"), where(documentId(), 'in', perfilUsuario.favoritos));
            getDocs(q)
            //getDoc(doc(getFirestore(), "startups", usuario.uid))
                .then((querySnapshot) => {
                    if (querySnapshot.size>0) {
                        console.log("CARGADAS = " + querySnapshot.size)
                        setStartups(querySnapshot.docs)
                        
                    } else {
                        console.log("NOHAY DATOS")
                    }
                    setCargada(true)
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setCargada(true)
        }
    }, [usuario, perfilUsuario])

    const removeFromFavorites = startupId  => {
        console.log("STARTUP = " + startupId)

        if (perfilUsuario.favoritos) {
            const index = perfilUsuario.favoritos.indexOf(startupId)
            console.log("INDEX = " + index)
           
            if (index > -1) {
                perfilUsuario.favoritos.splice(index, 1);
                setDoc(doc(getFirestore(), "users", usuario.uid), {
                     favoritos: perfilUsuario.favoritos
                    }, { merge: true })
                    .then(() => {
                        setPerfilUsuario (perfilUsuario)
                        console.log("ELIMINANDP STARTUPS")
                        const startupIndex = startups.indexOf(startupId)
                        let obj = startups.find((o, i) => {
                            if (o._key.path.segments.includes(startupId)) {
                                let nuevasStartups = startups.splice(i, 1);
                                setStartups(nuevasStartups)
                                return true; // stop searching
                            }
                        });
                        console.log ("STARTUPS=" + JSON.stringify(startups))
                        console.log("STARUP INDEX = " + startupIndex)
                    })
            }
        }
    }

    return (
    <div className="w-full mx-auto px-2 py-1">
        <div className="bg-white mt-5 p-4">
            <h1 className="pb-4">Dashboard</h1>
            <div className="flex flex-wrap -m-3.5"> {/* ROW */}

                <div className="max-w-xs w-80 p-4 border-r"> {/* COL */}
                    <div className="w-full">
                        <button className={"flex w-full p-4 "  + (mostrando==='favoritos'?"hover:bg-rose-700  hover:text-white p-4 bg-dpink text-white": "hover:bg-rose-700  hover:text-white bg-white")}
                                onClick={()=> {setMostrando('favoritos')}}>
                            {starIcon}
                            <span className="pl-2">Favoritos</span>
                        </button>
                    </div>
                </div>

                <div className="w-full max-w-full md:flex-grow md:w-0 p-4 px-6" > {/* COL */}
                    <div className="max-w-4xl">
                        {!cargada && <Loading/>}
                        {cargada && mostrando==='favoritos' && <Favoritos startups={startups} quitarDeFavoritos={removeFromFavorites} /> }
                    </div>
                </div>

            </div>
        </div>
    </div>
    )

}

export default Dashboard
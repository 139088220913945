import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import { FirebaseContext } from "../Firebase"

const RecuperarContrasena = () => {
    const { firebase } = useContext(FirebaseContext);
    const [error, setError] = useState('')
    const [email, setEmail] = useState("")

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async event => {
        event.preventDefault()
        setError("")
    
        firebase.auth.sendPasswordResetEmail(email)
            .then(() => {
                navigate("/login")
            })
            .catch((error) => {
                if ((error.code === "auth/invalid-email") || 
                    (error.code === "auth/invalid-value-(email)") ||
                    (error.code === "auth/invalid-value-(email),-starting-an-object-on-a-scalar-field")) {
                    setError("That email address is invalid!");
                }
                else {
                }
                return false
            });
    };


    return (
        <div className="w-full bg-white">
            <div className="max-w-sm mx-auto px-4 pt-10 pb-40">
                <h1 className=" text-center text-3xl sm:text-4xl font-bold text-dpurple nexaTextBold pb-5 sm:pb-9">
                    Recuperar contraseña
                </h1>
                <form name="Recupera" onSubmit={handleSubmit}>
                    <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                            autoComplete="off"
                            required
                            onChange={handleChange}
                        />
                    </div>

                    <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                        {
                        error ? 
                            <span className="text-red-600 capitalize nexaTextBold mb-4">{error}</span>
                            : null
                        }
                        <button
                            type="submit"
                            className="text-center pink-button"
                        >
                        Continuar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RecuperarContrasena
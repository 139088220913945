import React, { useState, useEffect, useContext } from "react"
import { Boton } from "@startuponsale/gatsby-shared-ui";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import Loading from "../Layout/Loading"
import { getFirestore, doc, getDoc, setDoc} from "firebase/firestore"
import  StartupTemplate, { calculateYears, currencyFormat} from "../Entidades/Proyecto";
import { FirebaseContext } from "../Firebase"

const starIcon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-star" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path></svg>
const starOffIcon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-star-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="3" y1="3" x2="21" y2="21"></line><path d="M10.012 6.016l1.981 -4.014l3.086 6.253l6.9 1l-4.421 4.304m.012 4.01l.588 3.426l-6.158 -3.245l-6.172 3.245l1.179 -6.873l-5 -4.867l6.327 -.917"></path></svg>

const Startup = (props) => {
    const { usuario, perfilUsuario, setPerfilUsuario } = useContext(FirebaseContext);
    const [startup, setStartupInfo] = useState(StartupTemplate)
    const [tipo, setTipo] = useState(StartupTemplate.tipoProyecto)
    const [yearsFounded, setYearsFounded] = useState(calculateYears(StartupTemplate.fechaFundacion.toDate()))
    const [cargada, setCargada] = useState(false)
    const [esFavorito, setEsFavorito] = useState(false)

    useEffect(() => {
        getDoc(doc(getFirestore(), "startups", props.id))
            .then((loadedInfo) => {
                if (loadedInfo.data()) {
                    setStartupInfo(loadedInfo.data())
                    setTipo(loadedInfo.data().tipoProyecto.toLowerCase())
                    setYearsFounded(calculateYears(loadedInfo.data().fechaFundacion.toDate()))
                }
                setCargada(true)
            })
            .catch(err => {
                console.log(err)
            })
    },[ props.id])

    useEffect(() => {
        setEsFavorito(perfilUsuario.favoritos.includes(props.id))
    },[perfilUsuario])

    

    const addToFavorites = e  => {
        if (!perfilUsuario.favoritos) perfilUsuario.favoritos = []

        if (perfilUsuario.favoritos) {
            if(perfilUsuario.favoritos.indexOf(props.id) === -1) {
                perfilUsuario.favoritos.push(props.id)

                setDoc(doc(getFirestore(), "users", usuario.uid), {
                     favoritos: perfilUsuario.favoritos
                  }, { merge: true })
                .then(() => {
                    setPerfilUsuario (perfilUsuario)
                    setEsFavorito(true)
                })
            }
        }
    }
    
    const removeFromFavorites = e  => {

        if (perfilUsuario.favoritos) {
            const index = perfilUsuario.favoritos.indexOf(props.id)
           
            if (index > -1) {
                perfilUsuario.favoritos.splice(index, 1);
                setDoc(doc(getFirestore(), "users", usuario.uid), {
                     favoritos: perfilUsuario.favoritos
                    }, { merge: true })
                    .then(() => {
                        setPerfilUsuario (perfilUsuario)
                        setEsFavorito(false)
                    })
            }
        }
    }


    if (!cargada)
        return <Loading/>
    else
        return (
            <div className="bg-white container mt-5 p-4">
            <div className="flex flex-wrap -m-3.5"> {/* ROW */}
            <div className="w-full max-w-full md:flex-grow md:w-0 p-4" > {/* COL */}
                <div className="max-w-4xl"> 
                <h5 className="nexabold text-dblue uppercase tracking-wider"><Link to={"/startups/"+tipo}>{startup.tipoProyecto}</Link></h5>
                <h2 className="text-2xl">{startup.descripcionCorta}</h2>
                <p className="text-gray-500 mt-2 mb-4 text-lg">{startup.descripcion}</p>
                <div className="flex flex-wrap mb-4">
                    {startup.tags &&
                        startup.tags.map((tag) => {
                        return <span key={tag} className={`tag mr-1 mt-1 text-sm ${tag}`}>{tag}</span>;
                        })}
                </div>
                <div className="flex flex-wrap justify-between mb-6">
                    <div className="pr-4 border-r mr-2">
                        <span className="text-gray-500 text-xs">Antiguedad</span>
                        <h5 className="nexabold">{yearsFounded} Años</h5>
                    </div>
                    <div className="pr-4 border-r mr-2">
                        <span className="text-gray-500 text-xs">Ventas Anuales</span>
                        <h5 className="nexabold text-dpurple">{currencyFormat(startup.ventasAnuales)}</h5>
                    </div>
                    <div className="pr-4 border-r mr-2">
                        <span className="text-gray-500 text-xs">MRR</span>
                        <h5 className="nexabold">{currencyFormat(startup.mrr)}</h5>
                    </div>
                    <div className="pr-4 border-r mr-2">
                        <span className="text-gray-500 text-xs">Equipo</span>
                        <h5 className="nexabold">{startup.equipo} persona/s</h5>
                    </div>
                    <div className="pr-4 border-r mr-2">
                        <span className="text-gray-500 text-xs">Clientes</span>
                        <h5 className="nexabold">{startup.customers}</h5>
                    </div>
                    <div className="pr-4 ">
                        <span className="text-gray-500 text-xs">Modelo Negocio</span>
                        <h5 className="nexabold">{startup.businessModel}</h5>
                    </div>
                </div>
                <div className="">
                    <h2 className="text-xl w-full">Tech Stack</h2>
                    <div className="">
                    {startup.techStack}
                    </div>
                </div>

                {/* Price Block for small screens */}
                <div className="block md:hidden">
                    <div className="mx-auto mt-10">
                    <div className="mx-auto flex justify-center">
                    {tipo === "ecommerce" ? (
                        <StaticImage src="../../images/types/ecommerce.png" alt="" layout="fixed" height={170}/>
                        ) : (
                        tipo === "marketplace" ? 
                        <StaticImage src="../../images/types/marketplace.png" alt="" layout="fixed" height={170}/> : (
                            (
                            tipo === "app" ? 
                                <StaticImage src="../../images/types/app.png" alt="" layout="fixed" height={170}/> : (
                                <StaticImage src="../../images/types/shopify.png" alt="" layout="fixed" height={170}/>
                        ))))}
                    </div>
                    <div className="px-6 pt-4 md:p-6">
                    <div className="text-gray-500">Precio Inicial</div>
                        <div className="nexabold mt-2 mb-0 text-xl text-dpurple">
                        <span>{startup.precioVenta===0 ? 
                            "Negociable" : 
                            currencyFormat(startup.precioVenta)}</span>
                        </div>
                    </div>

                    <div className="mx-6 mt-4">
                        <Boton link={"/contactanos"} text="Quiero información" />
                    </div>
                    </div>
                </div>

                </div>
            </div>

            <div className="max-w-full w-full md:w-auto p-4">
                <div className="w-72 lg:w-96">
                {/* Price Block for large screens */}
                <div className="hidden md:block">
                    <div className="border-l mx-auto ">
                    <div className="mx-auto flex justify-center">
                    {tipo === "ecommerce" ? (
                        <StaticImage src="../../images/types/ecommerce.png" alt="" layout="fixed" height={170}/>
                        ) : (
                        tipo === "marketplace" ? 
                        <StaticImage src="../../images/types/marketplace.png" alt="" layout="fixed" height={170}/> : (
                            (
                            tipo === "app" ? 
                                <StaticImage src="../../images/types/app.png" alt="" layout="fixed" height={170}/> : (
                                <StaticImage src="../../images/types/shopify.png" alt="" layout="fixed" height={170}/>
                        ))))}
                    </div>
                    <div className="pt-4 md:p-6 md:mx-auto">
                    <div className="text-gray-500">Precio Inicial</div>
                        <div className="nexabold mt-2 mb-0 text-xl text-dpurple">
                        <span>{startup.precioVenta===0 ? 
                            "Negociable" : 
                            currencyFormat(startup.precioVenta)}</span>
                        </div>
                    </div>

                    <div className="mx-6 mt-4">
                        {perfilUsuario.tipoCuenta==='comprador' &&
                            <Link
                                to={"/startups/"+  props.id + "/mensajes/" + usuario.uid} 
                                className="blue-button"
                            >
                                Contactar con el vendedor
                            </Link>
                        }
                    </div>
                    <div className="mx-6 mt-4">
                        {perfilUsuario.tipoCuenta==='comprador' && !esFavorito &&
                            <a href="#" className='gray-empty-button' onClick={addToFavorites}>
                                {starIcon} &nbsp;&nbsp; Marcar como Favorito
                            </a>
                        }
                        {perfilUsuario.tipoCuenta==='comprador' && esFavorito &&
                            <a href="#" className='gray-button' onClick={removeFromFavorites}>
                                {starOffIcon} &nbsp;&nbsp; Desmarcar como Favorito
                            </a>
                        }
                    </div>
                    </div>
                </div>
                </div>
            </div>

            </div>


                    
        </div>
        )
}

export default Startup
import React from "react";
import { Link } from "gatsby";

const Boton = ({ link, text }) => {
  return (
    <Link
      to={link}
      className="block text-base text-white nexaTextBold bg-dpink rounded-lg px-5 py-2 transition-all hover:shadow-xl hover:bg-pink-700"
    >
      {text}
    </Link>
  );
};

export default Boton;

import React from "react";

const Newsletter = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-4xl mx-auto px-4 pt-20 pb-40">
        <h1 className=" text-center text-4xl sm:text-5xl font-bold text-dpurple nexaTextBold pb-5 sm:pb-9">
          Suscribete a nuestra Newsletter
        </h1>
        <p className="text-center text-sm sm:text-base text-dpurple font-normal NexaTextDemoLight pb-16">
          Si quieres que te informemos de cuando estaremos operativos y de las
          primeros proyectos que se publiquen,
          <br /> dejanos tu dirección de correo
        </p>
        <form method="POST" name="Newsletter" data-netlify="true">
          <input type="hidden" name="form-name" value="Newsletter" />
          <div className="relative flex sm:block flex-col-reverse w-full ">
            <span className=" sm:absolute inset-y-0 right-0 flex items-center sm:pl-2">
              <button
                type="submit"
                className=" bg-dpink w-full transition-all hover:shadow-xl rounded-lg text-white px-10 py-4 cursor-pointer"
              >
                Quiero estar informado
              </button>
            </span>
            <input
              type="email"
              name="email"
              className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
              placeholder="Escribe tu dirección de correo aquí"
              autocomplete="off"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
